.controls {
    text-align: left;
    margin-left: 5px;
}
button:focus,button::-moz-focus-inner { outline: none; border: 0}
.controls button {
    font-weight: bold;
    background: #09080c;
    margin: 3px;
    height: 35px;
    color: #CCC;
    border: 1px outset rgba(255, 255, 255, 0.125);
    border-radius: 5px;
}
.controls button.power {
    width: 105px;
}
.controls button.power.playing {
    color: #82ff65;;
}
.controls .tempo {
    display: inline-block;
    margin-left: 3px;
}
.controls .tempo button {
    width: 35px;
    font-weight: bold;
}

.controls .version input {
    width: 130px;
    text-align: center;
}
.controls {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

table {
    width: 100%;
    table-layout: fixed;
    border: 0;
    border-spacing: 5px;
    background: rgba(0, 0, 0, 0.7);
    height: 92vh;
}

table td, table th {
    font-weight: normal;
    color: #ddd;
    border-radius: 5px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
    border: 2px outset rgba(255, 255, 255, 0.125);
    transition: all 0.2s;
    white-space: nowrap;
}

table th.first, table td.first {
    width: 5.882352941%;
    text-transform: uppercase;
    overflow: hidden;
    cursor: pointer;
}

table th.first.record, table tr:hover th.first.record {
    color: red;
}
table tr:hover th.first {
    color: #ff256f;
}

table tr.positions th {
    width: 5.882352941%;
}
table tr.positions {
    text-indent: -9999px;
    margin: 0;
}

table tr.positions th.use {
    background: #82ff65;
    text-indent: 0;
    color: #000;
    font-size: 12px;

    font-weight: bolder;
}
table tr.positions th.first {
    border: 0;
    background: rgba(0,0,0,0.1);
}

table td.hasInstrument {
    background: #ff256f;
}

table td.hasInstrument.use {
    background: #ff1635;
}

table td.play {
    cursor: pointer;
}
