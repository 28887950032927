body {
  color: #fff;
  text-align: center;
  background: rgba(0,0,0,0.9);
}

h1 {
  margin: 0 0 0 5px;
  font-size: 15px;
  text-align: left;
}

h1 span {
  color: #ccc;
  display: inline-block;
  font-size: 10px;
  margin-left: 5px;
}



